<template>
    <div class="grid">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <ConfirmPopup></ConfirmPopup>
    
      <div class="col-12">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  {{ CustomerDetails.name }} {{ $t('details') }}
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <!-- <Button
                  iconPos="right"
                  label="Create Ticket"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Create Ticket'"
                  @click="openNew"
                  size="small"
                  style="box-shadow: 0 2px 6px rgba(0,176,176,.549);border-color: #00b0b0!important;background-color: #00b0b0!important;"
                /> -->
                <Button
                  :label="$t('edit')"
                  v-tooltip.bottom="$t('edit')"
                  @click="edit_customer()"
                  size="small"
                  style="
                  
                  border-color: #006b00 !important;
                  background-color: #006b00 !important;
                "
                />&nbsp;
                <Button
                  :label="$t('back')"
                  v-tooltip.bottom="$t('back')"
                  @click="Goback"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 md:col-12">
			

			<div class="card p-fluid" id="custom_card">
				
				<div class="formgrid grid">
					<div class="field col-4">
						<label class="label_css">{{ $t('name') }}:</label>
						<p class="p_css">{{ CustomerDetails.name }}</p>
					</div>
                    <div class="field col-4">
						<label class="label_css">{{$t('email')}}:</label>
						<p class="p_css">{{ CustomerDetails.email_id }}</p>
					</div>
                    <div class="field col-4">
						<label class="label_css">{{$t('mobile')}}:</label>
                        <p class="p_css">{{ CustomerDetails.mobile_number }}</p>

					</div>
                    <div class="field col-4">
						<label class="label_css">{{$t('gender')}}:</label>
                        <p class="p_css">{{ CustomerDetails.gender }}</p>

					</div>
                    <div class="field col-4">
						<label class="label_css">{{ $t('created_on') }}:</label>
                        <p class="p_css">{{ D_M_Y_formatDate(CustomerDetails.created_at) }}</p>

					</div>
                    <div class="field col-4">
						<label style="
                              color: #6b719b;
                              font-size: 15px;
                              font-weight: 800;
                              
                            " for="name2">{{$t('profile')}}:</label><br>
						<img
                            style="border-radius: 50%; width: 80px; height: 80px"
                            v-if="CustomerDetails.profile_image"
                            :src="CustomerDetails.profile_image"
                            :alt="CustomerDetails.profile_image"
                          />
                          
                          <span v-else-if="CustomerDetails.name" class="i-circle" :style="'background-color:'+getcolorcode(CustomerDetails.name)">{{convert_name(CustomerDetails.name)}}</span>
                         
					</div>
                    <div class="field col-4">
						<label class="label_css">{{ $t('about') }}:</label>
                        <p class="p_css" v-html=" CustomerDetails.about"></p>

					</div>
                    
					
				</div>
			</div>
		</div>












      <div class="col-12">
        <div id="custom_card" class="card">
          <!-- <Breadcrumb :home="breadcrumbHome" :model="breadcrumbItems" /> -->
          <div class="grid p-fluid">
            <div class="col-12 md:col-9">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-3">
                  <span class="p-float-label">
                    <InputText
                      format="text"
                      v-model="search_ticket"
                      @change="search(1)"
                    >
                    </InputText>
                    <label>{{ $t('search') }}</label>
                  </span>
                </div>
                <div class="field col-12 md:col-3">
                  <span class="p-float-label">
                    <Dropdown
                      id="Category"
                      v-model="ticket_category_selected"
                      :options="ticket_category_dropdown"
                      :value="ticket_category_dropdown"
                      optionLabel="name"
                      filter
                      v-on:change="search(1)"
                    ></Dropdown>
                    <label for="Category">{{$t('dept')}}</label>
                  </span>
                </div>
                <div class="field col-12 md:col-3">
                  <span class="p-float-label">
                    <Dropdown
                      id="status"
                      v-model="ticket_status_selected"
                      :options="ticket_status_dropdown"
                      :value="ticket_status_dropdown"
                      optionLabel="name"
                      filter
                      v-on:change="search(1)"
                    ></Dropdown>
                    <label for="status">{{$t('status')}}</label>
                  </span>
                </div>
                <div class="field col-2 md:col-2">
                
                  <Button
                    icon="pi pi-refresh"
                    v-tooltip.bottom="'Refresh'"
                    @click="search(2)"
                    class="p-button-danger"
                    style="
                    box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;
                  "
                  />
                </div>
              </div>
            </div>
          </div>
    
          <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true"
            showGridlines
          >
            <template #empty>
              <b style="text-align: center">{{ $t('no_records_found') }}</b>
            </template>
            <Column :header="$t('sr_no')" style="min-width: 3rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
            <Column :header="$t('ticket_no')" style="min-width: 5rem">
            <template #body="{ data }">
                <u style="cursor: pointer;color: #00b0b0;" @click="view_tkt(data)">{{ data.ticket_no }}</u>
            </template>
          </Column>
          <Column :header="$t('ticket_title')" style="min-width: 10rem">
            <template #body="{ data }">
              
              <span style="cursor: pointer;">{{ data.title }}</span>
            </template>
          </Column>
          <Column :header="$t('category')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.ticket_category_name }}
            </template>
          </Column>
          <Column :header="$t('created_on')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ D_M_Y_formatDate(data.created_at) }}
            </template>
          </Column>
          <Column :header="$t('status')" style="min-width: 5rem">
            <template #body="{ data }">
              
                <Badge :style="{
                        'box-shadow': boxshadow(data.ticket_status.marathi),
                        'border-color': bordercolor(data.ticket_status.marathi),
                        'background-color': getBackgroundColor(data.ticket_status.marathi),
                        'color': '#ffffff !important', // Text color, adjust as needed
                      }" :value="data.ticket_status.name"></Badge>
            </template>
          </Column>
            
            
          </DataTable>
          <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete ?</span
              >
            </div>
            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deleteuser"
              />
            </template>
          </Dialog>
  
          <Dialog
            v-model:visible="edit_agent_model"
                :breakpoints="{'960px': '75vw'}"
            :style="{ width: '60vw' }"
            header="Add Agent"
            :modal="true"
                position="top"
          >
        
          <div class="col-12">
            <div class="p-fluid formgrid grid" style="margin-top: 2%;">
              <div class="field col-12 md:col-12" style="margin-top: 1%;">
                <span class="p-float-label">
                  <MultiSelect 
                      v-model="agent_selected" 
                      :options="agent_dropdownItems"
                      :value="agent_dropdownItems._id"
                      optionLabel="name" 
                      filter 
                      display="chip" 
                      :maxSelectedLabels="10">
                  </MultiSelect>
                  <label for="name">Name</label>
                </span>
              </div>
            </div>
          </div>
          <template #footer>
                <Button label="Save" @click="assign_agent" icon="pi pi-check" class="p-button-success"/>
              </template>
          </Dialog>

         

        </div>
      </div>
    </div>
  </template>
    <script>
  import { FilterMatchMode } from "primevue/api";
  import ProductService from "../service/ProductService";
  
  import ColorService from "../service/ColorService";
  import BreadcrumbService from "../service/BreadcrumbService";
  import JsonExcel from "vue-json-excel";
  //import FormNewsMaster from "@/components/FormNewsMaster";
  //import * as Survey from "survey-vue";
  // import axios from "axios";
  import apis from "@/apis";
  import axios from "axios";
  export default {
    data() {
      return {
        id:"",
        cat_title:"",
        colorHEX:"6b719b",
     

        ColorService:null,
      color_data:[],
        CustomerDetails:[],
        Category:"",
        //search
        agent_row_data:[],
        edit_agent_model:false,
        search_ticket: "",
        category_id: "",
        agent_dropdownItems:[],
        agent_selected:"",
        ticket_category_dropdown: [],
        ticket_category_selected: "",
        status_id: "",
        ticket_status_dropdown: [],
        ticket_status_selected: "",
        user_type: "",
        page_no: 1,
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        limit: 10,
        columns: [],
        pageno: 1,
        submitted: false,
        totalRecords: 0,
        id: "",
        BreadcrumbService: null,
        breadcrumbHome: {},
        breadcrumbItems: [],
        customer_id:"",
      };
    },
    productService: null,


    async created() {
      this.productService = new ProductService();
      this.initFilters();
      this.ColorService = new ColorService();
      this.BreadcrumbService = new BreadcrumbService();
      let Breadcrum_data = this.BreadcrumbService.getBreadcrum(
        "Ticket",
        "/tickets"
      );
      this.breadcrumbHome = Breadcrum_data.home;
      this.breadcrumbItems = Breadcrum_data.items;
      await this.getTicketCategoryMaster();
      await this.getAgentMaster();
      await this.getTicketStatusMaster();
    },
    async mounted() {
      this.ColorService.getColors().then((colors) => {
			this.color_data = colors;
		 
		  });
      this.id = this.$route.params.id;
      this.user_type = localStorage.getItem("user_type");
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if(localStorage.getItem('CustomerDetails'))
      {
        this.CustomerDetails=await JSON.parse(localStorage.getItem('CustomerDetails'));
        this.customer_id=this.CustomerDetails._id;

      }
      await localStorage.setItem('CustomerDetails','');
      if (this.totalRecords == 0) {
        await this.get_count();
      }


     
      
      await this.get_list();
     
    },
    watch: {
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    computed: {
    currentLocale() {
      
      return this.$i18n.locale;
    }
   
  },
    methods: {
       
        async view_tkt(data)
        {     var details=
            {
                ticket_status:data.ticket_status,
                ticket_no:data.ticket_no
            }
            await localStorage.setItem('TicketDetails','');
            await localStorage.setItem('TicketDetails',JSON.stringify(details));
            this.$router.push({name:"ticket_details"});
        },
      async edit_customer()
    { 
        
        await localStorage.setItem("CustomerDetails", "");
        await localStorage.setItem("CustomerDetails",JSON.stringify(this.CustomerDetails));
        this.$router.push({ name: "CustomerAddEdit" });

      
    },
      getcolorcode(name)
      {
        var name=this.convert_name(name)
        var temp=name.substring(0, 1);
        for (let index = 0; index < this.color_data.length; index++) 
        {
         if (this.color_data[index].name==temp) 
         {
            return this.color_data[index].code;
         }
          
        }
      },
    convert_name(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
        
      },
      async Goback() {
        this.$router.push({ name: "customers" });
      },
      async edit_agent(data)
      { var temp=[];
        for (let index = 0; index < data.agent_details.length; index++) 
        {
          temp.push({_id:data.agent_details[index].agent_id , name:data.agent_details[index].agent_name});
        }
        this.agent_selected=temp;
        this.agent_row_data=data;
        this.edit_agent_model=true;
      },
      async show_Agent(info,index)
      {
       console.log(info);
       console.log(index);
      },
       D_M_Y_formatDate(date_parm) {
          if (date_parm) {
            let date = new Date(date_parm);
            let dd = date.getDate();
            let mm = date.getMonth() + 1;
            let yyyy = date.getFullYear();
            let new_date = dd + "/" + mm + "/" + yyyy;
            // alert(dd)
            if (dd.toString() != "NaN") {
              //console.log(new_date);
              return new_date;
            } else {
              return date_parm;
            }
          }
        },
      async assign_agent()
      { var agent=[];
        for (let index = 0; index < this.agent_selected.length; index++) 
        {
          agent.push(this.agent_selected[index]._id);
        }
        let details={
                      "ticket_no": this.agent_row_data.ticket_no,
            "client_id":"65a4f82f6177e69880ece5d6",
            "agent_id":agent,
            "user_id":localStorage.getItem("id"),
            lang:this.$i18n.locale
                  }
         
                  var promise = apis.assignAgent(details);
                      promise.then((response) => {
                          this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
              this.edit_agent_model=false;
              this.get_list();
                  this.get_count();		
                      });
      },
      async update_status(ticket_no,status)
      { 
       
        if (!ticket_no || !status) 
        {
          this.$swal("Error !!!");
        }
        let details={
                      "user_id": localStorage.getItem("id"),
            "ticket_no":ticket_no,
            "client_id":localStorage.getItem("client_id"),
            "status":status._id,
            lang:this.$i18n.locale,
                  }
  
                  var promise = apis.updateTicketStatus(details);
                      promise.then((response) => {
                          this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
              this.get_list();
                  this.get_count();		
                      });
      },
      async search(count) {
        if (count == 2) {
          this.search_ticket = "";
          this.category_id = "";
          this.ticket_category_selected = "";
          this.status_id = "";
          this.ticket_status_selected = "";
        }
        await this.get_list();
        await this.get_count();
      },
      async viewProduct(product) {},
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      async getTicketCategoryMaster() {
        var data = {
          client_id: "65a4f82f6177e69880ece5d6",
          user_id: localStorage.getItem("id"),
        };
        // this.isLoadingModel = true;
        var promise = apis.TicketCategoryMaster(data);
        promise.then((response) => {
          // this.isLoadingModel = false;
          this.ticket_category_dropdown = response.data.data;
        });
      },
      async getAgentMaster() {
        var data = {
          client_id: "65a4f82f6177e69880ece5d6",
          user_id: localStorage.getItem("id"),
        };
        
        var promise = apis.agentListDropdown(data);
        promise.then((response) => {
          var temp=[];
          for (let index = 0; index < response.data.data.length; index++)
          {
            temp.push({_id:response.data.data[index].agent_id , name:response.data.data[index].agent_name});
            
          }
          this.agent_dropdownItems = temp;
        });
      },
      async getTicketStatusMaster() {
        var data = {
          client_id: "65a4f82f6177e69880ece5d6",
          user_id: localStorage.getItem("id"),
        };
        // this.isLoadingModel = true;
        var promise = apis.TicketStatusMaster(data);
        promise.then((response) => {
          // this.isLoadingModel = false;
          this.ticket_status_dropdown = response.data.data;
        });
      },
      get_count: function () {
       
        //get category id
        this.category_id = this.ticket_category_selected._id;
       
        //get status
        this.status_id = this.ticket_status_selected._id;
        var data = {
          category: this.category_id,
          user_id: localStorage.getItem("id"),
          "customer_id":this.customer_id,
          title: "",
          
          type: "",
          status: this.status_id,
          client_id: localStorage.getItem("client_id"),
          ticket_no: this.search_ticket,
          agent_id: "",
          
          count: true,
          
        };

        if (this.customer_id) {
            this.loading = true;
        var promise = apis.ticketlist(data, this.id);
        promise.then((response) => {
          this.loading = false;
          this.totalRecords = response.data.data;
        });
        }
        
        //console.log(data);
      },
      get_list: function () {
       
        //get category id
        this.category_id =  this.ticket_category_selected._id;
       
        //get status
        this.status_id = this.ticket_status_selected._id;
        var data = {
          user_id: localStorage.getItem("id"),
          "customer_id":this.customer_id,
          title: "",
          category: this.category_id,
          type: "",
          status: this.status_id,
          client_id: localStorage.getItem("client_id"),
          ticket_no: this.search_ticket,
          agent_id: "",
          
          page_no: this.page_no,
          limit: this.limit,
          count: false,
        };
        if (this.customer_id) 
        {
            this.loading = true;
            var promise = apis.ticketlist(data, this.id);
            promise.then((response) => {
            this.loading = false;
            this.products = response.data.data;
            if (this.columns.length == 0) {
                if (this.products.length > 0) {
                this.columns = Object.keys(this.products[0]);
                }
            }
            });
        }
      },
      getBackgroundColor(data)
      {
        if (data === 'In Progress') {
        return '#ffc107 !important';
        } else if (data === 'Open') {
          return '#00B064 !important';
        } else if (data === 'Closed') {
          return '#fc544b !important';
        } 
      },
      bordercolor(data)
      {
        if (data === 'In Progress') {
        return '#ffc107 !important';
        } else if (data === 'Open') {
          return '#00B064 !important';
        } else if (data === 'Closed') {
          return '#fc544b !important';
        } 
      },
      boxshadow(data)
      {
        if (data === 'In Progress') {
        return '0 2px 6px #ffc107';
        } else if (data === 'Open') {
          return '0 2px 6px #00B064';
        } else if (data === 'Closed') {
          return '0 2px 6px #fd9b96';
        } 
      },
      openNew() {
        this.product = {};
        this.submitted = false;
        this.productDialog = true;
        this.$router.push({ name: "create_ticket" });
      },
      async editProduct(product) {
        this.file_attachment = "";
        this.product = { ...product };
        await localStorage.setItem('TicketDetails','');
        await localStorage.setItem('TicketDetails',JSON.stringify(product));
        this.$router.push({name:"ticket_details"});
        //console.log(localStorage.getItem("TicketDetails"));
      },
      confirmDeleteProduct(product) 
      {
        this.$confirm.require({
                  target: event.currentTarget,
                  message: 'Are you sure you want to Delete ticket?',
                  icon: 'pi pi-exclamation-triangle',
                  accept: () => {
  
  
                      var payload=
                      {
                        "client_id":"65a4f82f6177e69880ece5d6",
                        "user_id":localStorage.getItem("id"),
                        "ticket_no":product.ticket_no
  
                      }
          
                      this.isLoadingModel = true;
                      var promise = apis.deleteTicket(payload);
                      promise.then((response) => {
                          this.isLoadingModel = false;
                          if (response.data.status==true) 
                          {
                              this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
                              this.get_list();
                              this.get_count();
                              window.scrollTo({ top: 0, behavior: 'smooth' });
                              
                          }
                          else
                          {
                              this.$toast.add({ severity: 'error', summary: 'ERROR!!!', detail: response.data.message, life: 3000 });
                          }
                      });
  
                  },
                  reject: () => {
                      this.$toast.add({ severity: 'error', summary: 'Cancel', detail: 'You have Cancelled', life: 3000 });
                  }
              });
          
      },
      
      async exportCSV() {
        this.limit = this.totalRecords;
        this.get_list();
        await this.$refs.dt.exportCSV();
      },
      confirmDeleteSelected() {
        this.deleteProductsDialog = true;
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: { downloadExcel: JsonExcel },
  };
  </script>
    <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  #custom_card {
    border-top: 3px solid #6b719b;
  }
  
  
  .In_Progress {
    background-color: yellow;
    color:white /* Adjust the styling as per your requirement */
  }
  .Open {
    background-color: green;
    color:white /* Adjust the styling as per your requirement */
  }
  
  .Closed {
    background-color: red;
    color:white /* Adjust the styling as per your requirement */
  }
  
  .zoom {
  
   
    transition: transform .2s;
  
  
  }
  .zoom:hover {
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
  }

  .label_css{
    color: #6b719b;
                              font-size: 15px;
                              font-weight: 800;
  }
  .p_css{
    color: #000000;
                              font-size: 15px;
                              font-weight: 600;
  }
  </style>
    